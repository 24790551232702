<template>
   <div class="container traffic-information-wrapper">
      <div class="traffic-information mb100">
         <div>
            <template>
               <div class="col-md-12 col-lg-12">
                  <div class="tabs_wrapper">
                     <!-- Tab headers -->
                     <div
                        class="tab_item_switch"
                        :class="{ active: activeType === 1 }"
                        @click.prevent="changeActiveType(1)"
                     >
                        <a href="#" class="switcher">{{
                           __t("current_traffic_situation")
                        }}</a>
                     </div>
                     <div
                        class="tab_item_switch"
                        :class="{ active: activeType === 2 }"
                        @click.prevent="changeActiveType(2)"
                     >
                        <a href="#" class="switcher">{{
                           __t("construction_measures")
                        }}</a>
                     </div>
                  </div>

                  <!-- Tab content -->
                  <div class="incidents-list">
                     <div class="incidents-list-in" v-show="activeType === 1">
                        <div
                           class="ids-webtools"
                           id="ids-webtools-messages"
                           :data-ids-url="messagesUrl"
                        ></div>
                     </div>

                     <div class="incidents-list-in" v-show="activeType === 2">
                        <div
                           class="ids-webtools"
                           id="ids-webtools-scheduled"
                           :data-ids-url="scheduledUrl"
                        ></div>
                     </div>
                  </div>
               </div>
            </template>
         </div>
      </div>
   </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";

export default {
   name: "TrafficInformation",
   data() {
      return {
         activeType: 1,
         messagesUrl:
            "https://abrm2.siteforum.com/de/app/webtools/messages.widget?&design=0&navigation=0&action=overview&scheduled=0",
         scheduledUrl:
            "https://abrm2.siteforum.com/de/app/webtools/messages.widget?&design=1&navigation=0&action=overview&scheduled=1",
      };
   },
   computed: {
      ...mapState([
         "region",
         "locales",
         "settings",
         "trafficNews",
         "constructionWork",
      ]),
   },
   methods: {
      changeActiveType(type) {
         this.activeType = type;
         this.loadExternalContent();
      },
      async loadExternalContent() {
         const updateElementWithUrl = async (elementId) => {
            const element = document.querySelector(`#${elementId}`);
            if (element) {
               const elementUrl = element.getAttribute(`data-ids-url`);
               try {
                  const resp = await axios.get(elementUrl, {
                     headers: {
                        Accept: "text/html",
                     },
                     responseType: "text",
                  });
                  element.innerHTML = resp.data;
                  this.interceptLinks(element);
               } catch (error) {
                  console.error(`Error updating element ${elementId}:`, error);
               }
            }
         };

         if (this.activeType === 1) {
            await updateElementWithUrl("ids-webtools-messages");
         } else if (this.activeType === 2) {
            await updateElementWithUrl("ids-webtools-scheduled");
         }
      },
      interceptLinks(element) {
         const links = element.querySelectorAll("a");
         links.forEach((link) => {
            link.addEventListener("click", (e) => {
               const url = link.href;
               if (url.endsWith(".pdf") || url.includes("mode=download")) {
                  e.preventDefault();
                  // console.log(`Intercepted download link: ${url}`);
                  this.downloadFile(url);
               } else {
                  e.preventDefault();
                  // console.log(`Intercepted link: ${url}`);
                  this.loadExternalLink(url, element);
               }
            });
         });
      },
      async loadExternalLink(url, element) {
         try {
            const resp = await axios.get(url, {
               headers: {
                  Accept: "text/html",
               },
               responseType: "text",
            });
            element.innerHTML = resp.data;
            this.interceptLinks(element); // Reapply link interception for new content
         } catch (error) {
            console.error(`Error loading external link ${url}:`, error);
         }
      },
      downloadFile(url) {
         const link = document.createElement("a");
         link.href = url;
         link.target = "_blank"; // Open in a new tab to avoid CORS issues
         link.rel = "noopener noreferrer"; // Security feature to prevent the new tab from having access to the original tab
         link.click();
      },
   },
   async mounted() {
      await this.loadExternalContent();
   },
};
</script>

<style lang="scss">
.switcher {
   width: 100%;
   text-align: center;
   border-top: 1px solid #f9f9f9;
   box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08), 0 2px 4px rgba(0, 0, 0, 0.03);
   display: block;
   padding: 10px;

   &.active {
      background: #ededed;
   }
}
.ids-nav-tabmenu {
   border: none;
   margin: 0 0 30px 0;
   padding: 0;
   display: flex;
   border: none;

   li {
      flex: 1;
      text-align: center;
      padding: 10px;
      border-bottom: 1px solid #6b717a;
      &.active {
         border-bottom: 6px solid #6b717a;
         a {
            color: #000 !important;
            border-bottom: none !important;
         }
      }
      & > a {
         display: inline-block;
         padding: 0;
         margin: 0;
         border: none;
      }
      &:hover {
         a {
            color: #000 !important;
            border-bottom: none !important;
         }
      }
   }
}

.ids-wt-lines {
   display: flex !important;
   justify-content: center !important;
   margin-bottom: 30px !important;
   flex-wrap: wrap !important;
   gap: 12px !important;
}

.ids-wt-lines a {
   padding: 5px 16px !important;
}

.ids-wt-lines {
   a {
      border-radius: 20px;
      padding: 0.3em 0.3em;
      font-size: 14px;
      color: #4a4a49;
      font-weight: 700;
   }
}
.ids-wt-body {
   padding: 0 !important;
}
// .ids-wt-body input[type="submit"],
// .ids-wt-body button,
// .ids-wt-body .ids-btn {
//    display: inline-block !important;
//    padding: 12px 20px 12px 20px !important;
//    border-radius: 4px !important;
//    background: #c71710 !important;
//    color: #fff !important;
//    font-size: 1rem !important;
//    line-height: 1.11111rem !important;
//    font-weight: bold !important;
//    cursor: pointer !important;
//    border: 0 none !important;
//    width: auto !important;
//    margin: 0 !important;
//    font-family: "Frutiger" !important;
//    font-weight: bold !important;
//    box-shadow: none !important;
// }

.ids-wt-lines {
   padding: 10px 0;
   a {
      padding: 5px 10px;
   }
}

.ids-panel {
   border-radius: 20px;
}
.ids-text-center {
   display: flex;
   justify-content: center;
   gap: 25px;
   align-items: center;
}

.ids-fgi-row > .ids-wt-fgi-list {
   margin-bottom: 20px;
   background: #fff;
   box-shadow: 5px 3px 14px rgba(0, 0, 0, 0.06), 0 2px 5px rgba(0, 0, 0, 0.07),
      0 1px 2px rgba(0, 0, 0, 0.06);
   border-radius: 12px;
   overflow: hidden;
   align-items: normal !important;
}

.ids-fgi-head,
.ids-fgi-body {
   background-color: #fff !important;
}
.ids-fgi-body a {
   font-size: 14px;
}
.ids-fgi-body {
   border-top: none !important;
   width: 65%;
   // position: relative !important;
}

.ids-wt-line-label {
   border-radius: 44px;
   text-align: center;
   font-size: 14px !important;
   // font-weight: normal !important;
   overflow: hidden;
   width: 60px;
   text-decoration: none;
   background-color: rgb(225, 226, 230);
   border: none;
   // border-left: 6px solid;
}

.ids-wt-fgi-list {
   display: flex;
   justify-content: space-between;
}
.ids-fgi-head {
   display: flex;
   align-items: flex-start;
   margin-top: 0 !important;
}

.ids-wt-line-label {
   border-radius: 44px;
   text-align: center;
   font-size: 14px;
   overflow: hidden;
   width: 60px;
   flex: none;
   font-weight: 700;
}

.ids-fgi-head .ids-wt-route {
   padding-left: 35px;
   position: relative;
   padding-right: 40px;
   margin-left: 40px;
   width: 100%;
   height: 100%;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   max-height: 100%;
   min-width: 250px;
}

.ids-fgi-head .ids-wt-route:before {
   content: "";
   background-image: linear-gradient(#6e6e6e 33%, hsla(0, 0%, 100%, 0) 0);
   background-position: 50%;
   background-size: 1px 2px;
   background-repeat: repeat-y;
   position: absolute;
   left: 0;
   top: 46%;
   width: 1px;
   height: 46%;
   bottom: auto;
   transform: translateY(-44%);
}

.ids-fgi-head .ids-wt-line-from,
.ids-fgi-head .ids-wt-line-to {
   font-size: 1rem;
   margin-bottom: 0 !important;
   opacity: 0.8;
   font-weight: bold;
   position: relative;
}

.ids-fgi-head .ids-wt-line-from .ids-wt-dot,
.ids-fgi-head .ids-wt-line-from .ids-wt-dot-active,
.ids-fgi-head .ids-wt-line-to .ids-wt-dot,
.ids-fgi-head .ids-wt-line-to .ids-wt-dot-active {
   position: absolute;
   left: -42px;
   width: 16px;
   height: 16px;
   border-radius: 50%;
}

.ids-fgi-head .ids-wt-line-from .ids-wt-dot,
.ids-fgi-head .ids-wt-line-to .ids-wt-dot {
   border: 1px solid #6e6e6e;
}

.ids-fgi-head .ids-wt-line-from .ids-wt-dot-active,
.ids-fgi-head .ids-wt-line-to .ids-wt-dot-active {
   border: 1px solid #ce132d;
}

.ids-fgi-head .ids-wt-line-from .ids-wt-dot,
.ids-fgi-head .ids-wt-line-from .ids-wt-dot-active {
   top: 3px;
}
.ids-fgi-head .ids-wt-line-to .ids-wt-dot,
.ids-fgi-head .ids-wt-line-to .ids-wt-dot-active {
   bottom: 6px;
}

.ids-fgi-head .ids-wt-line-from .ids-wt-dot::after,
.ids-fgi-head .ids-wt-line-to .ids-wt-dot::after,
.ids-fgi-head .ids-wt-line-to .ids-wt-dot-active::after,
.ids-fgi-head .ids-wt-line-from .ids-wt-dot-active::after {
   content: "";
   position: absolute;
   right: 1px;
   top: 1px;
   width: 16px;
   height: 16px;
   left: 1px;
   width: 12px;
   height: 12px;
   border-radius: 50%;
   bottom: 0;
}
.ids-fgi-head .ids-wt-line-from .ids-wt-dot::after,
.ids-fgi-head .ids-wt-line-to .ids-wt-dot::after {
   background-color: #6e6e6e;
}
.ids-fgi-head .ids-wt-line-to .ids-wt-dot-active::after,
.ids-fgi-head .ids-wt-line-from .ids-wt-dot-active::after {
   background-color: #ce132d;
}

.ids-fgi-head .ids-wt-line-to .ids-wt-dot::after {
   border: 1px solid #6e6e6e;
   background-color: #6e6e6e;
}

.ids-fgi-head .ids-wt-route .ids-wt-line-from .ids-wt-dot {
   border-color: #6e6e6e;
   top: 3px;
}
.ids-fgi-head .ids-wt-route .ids-wt-line-to .ids-wt-dot {
   bottom: 5px;
   top: auto;
}

.ids-fgi-head .ids-wt-line-separator,
.ids-wt-route .ids-wt-affected-separator {
   display: none;
}

.ids-wt-section {
   margin-bottom: 1rem;
}

.ids-wt-section .ids-text-headline,
.ids-wt-time .ids-text-headline {
   // font-size: 10px;
   margin: 0 !important;
   // line-height: 10px;
   color: #6b717a;
   opacity: 0.75;
   font-weight: 700;
}

.ids-wt-route {
   display: flex;
}

.ids-fgi-body .ids-wt-route .ids-wt-affected-from,
.ids-fgi-body .ids-wt-route .ids-wt-affected-to {
   position: relative;
   border-bottom: 2px solid #c71712;
   padding-bottom: 5px;
   width: 100%;
}

.ids-fgi-body .ids-wt-route .ids-wt-dot,
.ids-fgi-body .ids-wt-route .ids-wt-dot-active {
   position: absolute;
   left: 0;
   background-color: #6e6e6e;
   width: 12px;
   height: 12px;
   border-radius: 50%;
   bottom: -6.5px;
}

.ids-fgi-body .ids-wt-affected-to .ids-wt-dot-active {
   left: auto;
   right: -2px;
}

.ids-fgi-body .ids-wt-affected-from,
.ids-fgi-body .ids-wt-affected-to {
   font-size: 14px;
   font-weight: 700;
}

.ids-fgi-body .ids-wt-affected-to {
   text-align: right;
}

.ids-fgi-body .ids-wt-label {
   // font-size: 0.8em;
   font-weight: 400;
   margin: 0 !important;
}

.ids-wt-btn-more {
   margin: 0 !important;
   display: flex;
   align-items: flex-end;
   margin-left: 50px;
}
.ids-fgi-row .ids-btn {
   margin: 0 !important;
   margin-bottom: 0;
   background-color: transparent !important;
   border: none !important;
   background: none !important;
   box-shadow: none !important;
   padding: 0 !important;
   display: flex;
   min-width: 200px;
   margin-bottom: 24px !important;
}

.ids-fgi-row .ids-btn .ids-wt-more-txt {
   text-decoration: underline;
   font-weight: 700;
   font-size: 14px;
   color: #6b717a;
}
.ids-fgi-row .ids-btn .ids-wt-more-sign {
   border: 2px solid #6b717a;
   color: #6b717a;
   border-radius: 50%;
   width: 24px;
   height: 24px;
   text-align: center;
   margin-left: 18px;
   font-size: 18px;
   font-weight: 700;
   display: flex;
   align-content: center;
   justify-content: center;
   line-height: 18px;
}

.ids-btn.ids-wt-container-right {
   display: inline-block !important;
   padding: 12px 20px 12px 20px !important;
   border-radius: 4px !important;
   background: #c71710 !important;
   color: #fff !important;
   font-size: 1rem !important;
   line-height: 1.11111rem !important;
   font-weight: bold !important;
   cursor: pointer !important;
   border: 0 none !important;
   width: auto !important;
   margin: 0 !important;
   font-family: "Frutiger" !important;
   font-weight: bold !important;
   box-shadow: none !important;
}

/* Details View */
.ids-wt-fgi-detail {
   margin-bottom: 20px;
   background: #fff;
   box-shadow: 5px 3px 14px rgba(0, 0, 0, 0.06), 0 2px 5px rgba(0, 0, 0, 0.07),
      0 1px 2px rgba(0, 0, 0, 0.06);
   border-radius: 12px;
   overflow: hidden;
   align-items: normal !important;
}

.ids-wt-fgi-detail .ids-fgi-head {
   padding: 24px !important;
}

.ids-wt-fgi-detail .ids-wt-line-label {
   width: auto !important;
   font-size: 3.05556rem !important;
   line-height: 3.05556rem !important;
   background: none !important;
   font-weight: 700 !important;
   margin: 0 0 15px 0 !important;
   padding: 0 !important;
   word-break: break-word !important;
   border-radius: 0% !important;
   margin-top: 9px !important;
   letter-spacing: -0.7px !important;
   width: 100px !important;
}

.ids-wt-fgi-detail .ids-fgi-body {
   width: 100% !important;
   background-color: #f9f9f9 !important;
}

.ids-wt-fgi-detail .ids-wt-route {
   padding-left: 35px;
   position: relative;
   padding-right: 40px;
   margin-left: 40px;
   width: 100%;
   height: 100%;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   max-height: 100%;
   min-width: 250px;
   // border: 1px solid red;
}
.ids-wt-fgi-detail .ids-fgi-body .ids-wt-route {
   padding-left: 0;
   padding-right: 0;
   margin-left: 0;
   width: 100%;
   height: 100%;
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   max-height: 100%;
   min-width: 250px;
   margin-bottom: 25px;
}

.ids-wt-fgi-detail .ids-fgi-body {
   padding: 24px !important;
}

.ids-wt-fgi-detail .ids-fgi-head {
   height: 170px;
}

.ids-wt-fgi-detail .ids-wt-label.ids-text-headline {
   margin-bottom: 20px !important;
}

.ids-wt-fgi-detail .ids-wt-btn-back {
   margin-bottom: 0 !important;
   display: inline-block !important;
   padding: 12px 20px 12px 20px !important;
   border-radius: 4px !important;
   background: #c71710 !important;
   color: #fff !important;
   font-size: 1rem !important;
   line-height: 1.11111rem !important;
   font-weight: bold !important;
   cursor: pointer !important;
   border: 0 none !important;
   width: auto !important;
   margin: 0 !important;
   font-family: "Frutiger" !important;
   font-weight: bold !important;
   box-shadow: none !important;
   margin-top: 30px !important;
   min-width: auto !important;
}
.ids-wt-fgi-detail .ids-wt-btn-back a {
   margin-bottom: 0 !important;
   min-width: auto !important;
}

.ids-wt-fgi-detail .ids-wt-time {
   margin-bottom: 25px;
}
.ids-wt-label.ids-text-headline {
   font-weight: 700;
}
.ids-wt-info .ids-text-headline {
   color: #6b717a;
   opacity: 0.75;
   font-weight: 700;
}

// responsive
@media (max-width: 600px) {
}
</style>
